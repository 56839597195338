<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code flex items-center justify-center" />
    <div class="column container">
      <q-card
        square
        class="bg-white"
      >
        <q-card-section :class="`bg-primary text-white text-uppercase`">
          {{ $t('where_are_you_travelling') }}
        </q-card-section>
        <q-separator />
        <q-card-section class="column">
          <div class="row q-col-gutter-sm">
            <div class="col-6 center">
              <q-radio
                v-model="parameters.returning"
                :val="false"
                :label="$tc('one_way_return')"
              />
            </div>
            <div class="col-6 center">
              <q-radio
                v-model="parameters.returning"
                :val="true"
                :label="$tc('one_way_return', 2)"
              />
            </div>
          </div>
          <div class="q-col-gutter-sm content-center relative-position" :class="$q.platform.is.mobile ? 'row' : ''">
            <div :class="$q.platform.is.desktop ? 'row' : 'col-10'">
              <div class="col-10 col-sm-5">
                <m-select-query
                  v-model="parameters.origin"
                  :label="$t('going_from')"
                  stack-label
                  :placeholder="$t('input.origin')"
                  icon="place"
                  :query="autocomplete"
                  clearable
                />
              </div>
              <div v-if="$q.platform.is.desktop" class="justify-center flex icon-wrap col-2">
                <q-btn
                  flat
                  class="icon"
                  size="14px"
                  :icon="$q.platform.is.desktop ? 'fa fa-exchange' : 'fa fa-sort-alt'"
                  @click="swap"
                />
              </div>
              <div class="col-10 col-sm-5">
                <m-select-query
                  v-model="parameters.destination"
                  :label="$t('going_to')"
                  stack-label
                  :placeholder="$t('input.destination')"
                  icon="place"
                  :query="autocomplete"
                  clearable
                />
              </div>
            </div>
            <div v-if="$q.platform.is.mobile" class="justify-center flex icon-wrap col-2">
              <q-btn
                flat
                class="icon"
                size="14px"
                icon="fa fa-sort-alt"
                @click="swap"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <q-select
                v-model="parameters.adults"
                :options="parameters.adultsOptions"
                stack-label
                emit-value
                :label="$t('train.options.adults')"
              />
            </div>
          </div>
          <div class="row q-col-gutter-sm">
            <div class="col-6">
              <date-field
                v-model="parameters.depart"
                :min="minDepart"
                :label="$t('date.travel')"
              />
            </div>
            <div class="col-6">
              <time-field
                v-model="parameters.depart"
                :min="minDepart"
                :minute-step="15"
                :label="$t('time.from')"
              />
            </div>
          </div>
          <div v-if="parameters.returning" class="row q-col-gutter-sm">
            <div class="col-6">
              <date-field
                v-model="parameters.return"
                :min="minReturn"
                :label="$t('date.return')"
              />
            </div>
            <div class="col-6">
              <time-field
                v-model="parameters.return"
                :min="minReturn"
                :minute-step="15"
                stack-label
                :label="$t('time.return')"
              />
            </div>
          </div>
          <railcard-select
            :railcard-options="railcardOptions"
            :adults="parameters.adults"
            type="railcard"
            @update="val => railcards = val"
          />
        </q-card-section>
      </q-card>
      <div class="row">
        <div class="col">
          <m-unified-users v-model="people" />
        </div>
      </div>
      <div class="cta row">
        <q-btn
          :disabled="$v.$invalid"
          size="lg"
          color="primary"
          class="full-width submit"
          @click="goSearch"
        >
          {{ $tc('find.train', 2) }}
        </q-btn>
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { required, requiredIf } from 'vuelidate/lib/validators'
import { stations, railcards as getRailcards } from 'api/train'
import date from 'utils/date-time'
const { addToDate, roundMinutesUp } = date
import model from './model'
import authentication from 'mixins/authentication'
import searchLocation from 'mixins/searchLocation'
import { MSelectQuery, MUnifiedUsers, timeField, dateField } from 'components/index'
import railcardSelect from './railcards'
import { handleErrors } from 'utils/utils'

export default {
  components: {
    timeField,
    dateField,
    MSelectQuery,
    MUnifiedUsers,
    railcardSelect
  },
  mixins: [ authentication, searchLocation ],
  data () {
    return {
      parameters: model(),
      railcardOptions: [],
      railcards: []
    }
  },
  computed: {
    people: {
      get () {
        return {
          traveller: this.parameters.traveller,
          requester: this.parameters.requester
        }
      },
      set (val) {
        this.parameters.requester = val.requester
        this.parameters.traveller = val.traveller
      }
    },
    minDepart () {
      // Min departure param is (now + 15 minutes) rounded to the next 15 min
      return roundMinutesUp(addToDate(date.newDate(), { minutes: 15 }), 15)
    },
    minReturn () {
      return addToDate(this.parameters.depart, { minutes: 15 })
    }
  },
  validations: {
    parameters: {
      origin: { required },
      destination: { required },
      depart: { required },
      return: { required: requiredIf(function () { return this.parameters.returning }) },
      traveller: { required },
      requester: { required }
    }
  },
  watch: {
    '$route.params.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.origin = val
        }
      }
    },
    'parameters.depart' (val) {
      if (this.parameters.returning && this.parameters.return !== null && (this.parameters.depart > this.parameters.return)) {
        this.parameters.return = addToDate(this.parameters.depart, { hours: 4 })
      }
      if (val < this.minDepart) {
        this.parameters.depart = this.minDepart
      }
    },
    'parameters.return' (val) {
      if (val && val < this.minReturn) {
        this.parameters.return = this.minReturn
      }
    },
    '$route.query.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.origin = val
        }
      }
    },
    '$route.query.destination': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.destination = val
        }
      }
    }
  },
  created () {
    this.$store.commit('ondemand/clear')
    this.parameters.depart = this.minDepart
  },
  async beforeMount () {
    const { data } = await getRailcards()
    this.railcardOptions = data
  },
  methods: {
    goSearch () {
      const searchParameters = { ...this.parameters }
      const reducer = (accumulator, { code }) => {
        if (Object.keys(accumulator).includes(code)) {
          accumulator[code]++
        } else if (code) {
          accumulator[code] = 1
        }
        return accumulator
      }

      const railcardsToMap = this.railcards.reduce(reducer, {})
      const railcardsToQuery = {}
      let i = 0
      for (const key in railcardsToMap) {
        railcardsToQuery[`railcards[${i}][code]`] = key
        railcardsToQuery[`railcards[${i}][qty]`] = railcardsToMap[key]
        i++
      }

      this.$store.dispatch('ondemand/stash', { parameters: { ...searchParameters, railcards: railcardsToQuery } })
      this.$router.push({ name: 'ondemand-train-depart' })
    },
    async autocomplete (terms) {
      try {
        const { data } = await stations({ query: terms })
        const stationList = data.map(station => {
          return {
            label: station.name,
            value: station.atoc,
            icon: 'train',
            color: 'blue-8',
            append: station.atoc
          }
        })
        return stationList
      } catch (err) {
        handleErrors(err)
      }
    },
    swap () {
      [this.parameters.destination, this.parameters.origin] = [
        this.parameters.origin,
        this.parameters.destination
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>
.mobile
  .code
    display none
  .column.container
    margin 32px
  .cta
    background-color #fafafa
    padding 16px 8px
    position fixed
    left 0
    right 0
    bottom 0
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 5px 8px rgba(0,0,0,0.14), 0 1px 14px rgba(0,0,0,0.12);
.cta
  padding 8px
.code
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(255, 255, 255, .6)
  overflow hidden
  background-image url('~assets/backgrounds/train.jpg')
  background-position: 0 30%;
  background-size: cover;
  text-overflow: clip;
.column.container
  margin-top -64px
.q-card
  width 80vw
  max-width 600px
.center
  justify-content center
  display flex
</style>
