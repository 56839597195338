<template>
  <q-page>
    <div class="layout-padding">
      <div
        v-if="summary"
        class="row q-col-gutter-sm"
      >
        <div class="col-sm-12">
          <q-card class="bg-white">
            <q-card-section class="bg-primary">
              {{ $t('booking.summary') }}
            </q-card-section>
            <q-card-section>
              <div
                class="row"
                style="margin-top: 16px"
              >
                <sub class="col-12">
                  {{ $t('train.summary.ticket_collection') }}
                </sub>
                <h6 class="col-12 text-grey-9 delivery-text">
                  {{ delivery.text }}
                </h6>
              </div>
              <q-separator class="hr" />
              <rail-journey
                :journey="summary.journeys.outbound"
                :leg-name="$t('train.outbound')"
              />
              <rail-journey
                v-if="summary.journeys.return"
                :journey="summary.journeys.return"
                :leg-name="$tc('one_way_return', 2)"
              />
              <q-separator class="hr" />
              <ticket :ticket="summary.tickets[0]" />
              <ticket v-if="summary.journeys.return" :ticket="summary.tickets.length > 1 ? summary.tickets[1] : summary.tickets[0]" />
              <q-item class="item adults">
                <q-item-label>
                  <q-item-label label class="adult-text">
                    {{ adults }}
                  </q-item-label>
                </q-item-label>
              </q-item>
              <q-separator class="hr" />
              <div v-if="summary.mobilleo_booking_fee" class="row">
                <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('booking.fee') }}</span>
                <span class="col-2 text-grey-9 text-right">{{ summary.mobilleo_booking_fee.text }}</span>
              </div>
              <div v-if="summary.total_ticket_cost" class="row">
                <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('net_amount') }}</span>
                <span class="col-2 text-grey-9 text-right">{{ summary.total_ticket_cost.text }}</span>
              </div>
              <div v-if="summary.vat" class="row">
                <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('tax.vat') }}</span>
                <span class="col-2 text-grey-9 text-right">{{ summary.vat.text }}</span>
              </div>
              <q-separator class="hr" />
              <h6
                v-if="summary.total_cost"
                class="row text-bold"
              >
                <span class="offset-6 col-4 text-grey-9 text-right">{{ $t('total_cost') }}</span>
                <span class="col-2 text-grey-9 text-right">{{ summary.total_cost.text }}</span>
              </h6>
            </q-card-section>
          </q-card>
        </div>
        <div class="col-sm-12">
          <m-attach-to-booking-request
            :suggested="summary.suggested_journey_name"
            :username="stash.parameters.traveller.value"
            :journey="journey"
            :show-travel-type="organisation && organisation.attributes.travel_policy.rail.enabled"
            @change="(val) => $store.dispatch('ondemand/journey', val)"
            @reasonChange="(val) => reason_for = val"
            @labelChange="(val) => labels = val"
            @travelTypeChange="(val) => travelType = val"
          />
        </div>
        <payment-buttons
          :total-amount="summary.total_cost.amount"
          :requester="stash.parameters.requester"
          :journey="journey"
          :reason-for="reason_for"
          :labels="labels"
          :booking-token="stash.selected.ticket.booking_token"
          :travel-type="(organisation && organisation.attributes.travel_policy.rail.enabled) || !organisation ? travelType : 'Private'"
        />
        <sub
          class="col-12 text-center text-faded"
          style="margin-top: 8px"
          v-html="$t('mobilleo_privacy_and_terms', { privacyURL: partner.privacyURL, termsURL: partner.termsURL })"
        />
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { handleErrors } from 'utils/utils'
import store from 'store'
import authentication from 'mixins/authentication'
import { summary } from 'api/train'
import { mapGetters } from 'vuex'
import loading from 'utils/loading'
import paymentButtons from 'pages/payments/payment-method-buttons'
import railJourney from './rail-journey'
import ticket from './ticket'
import i18n from 'i18n'
import { MAttachToBookingRequest } from 'components/'

export default {
  name: 'OndemandTrainSummary',
  components: {
    paymentButtons,
    railJourney,
    ticket,
    MAttachToBookingRequest
  },
  mixins: [authentication],
  data () {
    return {
      summary: null,
      reason_for: null,
      labels: null,
      travelType: null
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash',
      journey: 'ondemand/journey',
      partner: 'partner',
      organisation: 'organisation'
    }),
    delivery () {
      return this.summary ? this.summary.delivery_options.filter(option => option.id === this.stash.delivery)[0] : {}
    },
    adults () {
      return this.$tc('num.adults', this.stash.parameters.adults)
    }
  },
  async beforeRouteEnter (to, from, next) {
    const { delivery, selected, seat } = store.getters['ondemand/stash']
    const partner = store.getters['partner']
    loading.start({
      message: i18n.t('loading.getting.ticket_summary'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const params = {
        delivery_method: delivery,
        collection_station: selected.depart.origin.atoc,
        seat_preferences: [
          seat.direction,
          seat.enviroment,
          seat.position,
          ...seat.options
        ].filter(Boolean)
      }

      const { data } = await summary(selected.ticket.booking_token, params)

      next(vm => {
        vm.summary = data
        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 768px
button
  width 100%
span
  margin-top 16px
.hr
  margin-top 24px
  margin-bottom 10px

.item
  padding-left 0

.delivery-text
  margin-bottom 0

.adults
  padding-bottom 0
  min-height: 0

.adult-text
  font-size: 14px!important
</style>
