<template>
  <q-card v-ripple class="row shadow-1 cursor-pointer" :class="classes" @click="selected">
    <div class="col-3 col-sm-2 q-py-md q-pl-md">
      <m-image
        :src="railwayLogo"
        height="56px"
      />
    </div>
    <div class="col-9 col-sm-6 q-pt-md q-pr-md text-faded">
      <div class="row bubble-top text-black">
        <div class="col-3 text-weight-medium">
          {{ train.depart.date | time }}
        </div>
        <div class="col-9">
          {{ train.origin.name | capitalise }}
        </div>
      </div>
      <div v-if="train.number_of_changes === 0" class="row bubble-line">
        <div class="col-9 offset-3 text-uppercase text-caption text-green">
          {{ $tc('num.changes', 0) }}
        </div>
      </div>
      <div v-for="(segment, key) in train.segments.data.slice(1)" v-else :key="key" class="row bubble-change bubble-line">
        <div class="col-3 text-caption text-weight-medium">
          <span>{{ segment.depart.date | time }}</span>
        </div>
        <div class="col-9 text-caption">
          {{ segment.origin | capitalise }}
        </div>
      </div>
      <div class="row bubble-bottom text-black">
        <div class="col-3 text-weight-medium">
          {{ train.arrive.date | time }}
        </div>
        <div class="col-9">
          {{ train.destination.name | capitalise }}
        </div>
      </div>
      <div class="col-12 q-my-sm text-caption">
        <span title="Duration">{{ train.duration.text | toHoursMins }}</span>
        <span> &middot; </span>
        <span class="desktop-only" :title="$t('train.initial_vendor')">{{ train.vendors[0] | capitalise }}</span>
        <span class="desktop-only"> &middot; </span>
        <span :title="$t('train.route_number')">{{ train.segments.data[0].route_number }}</span>
      </div>
    </div>
    <div class="col-12 col-sm-4 column q-pt-md q-px-md q-pb-xs pricing text-faded justify-between" :title="`${train.ticket.description} - ${train.ticket.restriction}`">
      <div class="row text-body2 text-blue-grey-5 flex items-center">
        <div class="col-12">
          <q-icon size="16px" name="mdi-ticket" /> &nbsp;&nbsp;{{ train.ticket.name | capitalise }}
        </div>
        <div v-if="train.eticket_available" class="col-12 q-mt-md-xs">
          <q-icon size="16px" name="fas fa-qrcode" /> &nbsp;&nbsp;{{ $t('train.eticket_available') }}
        </div>
      </div>
      <div
        class="q-mt-md"
        v-html="$t('from_price_per_ticket', { price: train.ticket.price.text })"
      />
    </div>
  </q-card>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import date from 'utils/date-time'
import railways from 'utils/railway'
import { MImage } from 'components/'

export default {
  filters: {
    time: value => date.toCivilTime(value),
    toHoursMins: value => value.replace(/ hours/, 'h').replace(/ minutes/, 'm').replace(/ hour/, 'h').replace(/ minute/, 'm'),
    capitalise: value => value[0].toUpperCase() + value.toLowerCase().slice(1)
  },
  components: {
    MImage
  },
  props: {
    train: null
  },
  data () {
    return {
      modal: false
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    isSelected () {
      return this.stash.selected.depart === this.train || this.stash.selected.return === this.train
    },
    classes () {
      return [this.isSelected ? 'selected bg-primary-tint shadow-3' : null,
        this.disabled ? 'disabled' : null]
    },
    disabled () {
      return date.newDate(this.train.depart.date) < date.newDate()
    },
    railwayLogo () {
      return `https://s3-eu-west-2.amazonaws.com/mobilleo-railwaylogos/${railways[this.train.vendors[0]] || 'XR'}_70.png`
    }
  },
  methods: {
    capitalise (string) {
      return string[0].toUpperCase() + string.toLowerCase().slice(1)
    },
    selected () {
      if (!this.disabled) {
        this.$emit('click', this.train)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .pricing
    border-style solid
    border-color $grey-4
    border-radius 0 !important
    border-width 0
    @media (min-width $breakpoint-md-min)
      border-left-width 1px
    @media (max-width $breakpoint-sm-min)
      border-top-width 1px

  @media (max-width $breakpoint-sm-min)
    .mobile-block
      display block
  @media (min-width $breakpoint-md-min)
    .bubble-change,
    .bubble-top,
    .bubble-line,
    .bubble-bottom
      position relative
      padding-left 8px
      &:before, &:after
        position absolute
        content ""
        background-color #DCDCDC
    .bubble-change,
    .bubble-top,
    .bubble-bottom
      &:before
        width 8px
        height 8px
        left -12px
        border-radius 100%
    .bubble-change:before
      top 6px
      border-radius 0
      transform rotate(45deg)
    .bubble-top:before
      bottom 8px
    .bubble-bottom:before
      top 8px
    .bubble-line:after
      width 2px
      top -8px
      bottom -8px
      left -9px
</style>
