<template>
  <q-page>
    <q-tabs
      v-model="currentTab"
      class="absolute-top bg-white text-primary"
      align="justify"
      no-pane-border
      @select="setSelected(null)"
    >
      <q-tab
        default
        :label="$t('train.class.standard')"
        name="standard"
      />
      <q-tab
        :label="$t('train.class.first')"
        name="first"
      />
    </q-tabs>
    <div class="layout-padding">
      <q-tab-panels v-model="currentTab" class="bg-transparent">
        <!-- Targets -->
        <q-tab-panel
          v-if="standard"
          name="standard"
        >
          <h6>{{ $t('best_price_for_time') }}</h6>
          <card
            v-for="ticket in standard.recommended"
            :key="ticket.booking_token"
            :ticket="ticket"
            @click.native="setSelected(ticket)"
          />
          <h6 v-if="standard.open_returns">
            {{ $t('return_within_one_month') }}
          </h6>
          <card
            v-for="ticket in standard.open_returns"
            :key="ticket.booking_token"
            :ticket="ticket"
            @click.native="setSelected(ticket)"
          />
          <h6 v-if="standard.other">
            {{ $t('train.other_tickets') }}
          </h6>
          <card
            v-for="ticket in standard.other"
            :key="ticket.booking_token"
            :ticket="ticket"
            @click.native="setSelected(ticket)"
          />
        </q-tab-panel>
        <q-tab-panel name="first">
          <div v-if="first && first.recommended.length">
            <h6>{{ $t('best_price_for_time') }}</h6>
            <card
              v-for="ticket in first.recommended"
              :key="ticket.booking_token"
              :ticket="ticket"
              @click.native="setSelected(ticket)"
            />
            <h6 v-if="first.open_returns">
              {{ $t('return_within_one_month') }}
            </h6>
            <card
              v-for="ticket in first.open_returns"
              :key="ticket.booking_token"
              :ticket="ticket"
              @click.native="setSelected(ticket)"
            />
            <h6 v-if="first.other">
              {{ $t('train.other_tickets') }}
            </h6>
            <card
              v-for="ticket in first.other"
              :key="ticket.booking_token"
              :ticket="ticket"
              @click.native="setSelected(ticket)"
            />
          </div>
          <m-empty-state
            v-else
            icon="train"
            class="layout-padding"
          >
            {{ $t('no_first_class_tickets') }}
          </m-empty-state>
        </q-tab-panel>
      </q-tab-panels>
    </div>
    <q-footer
      v-if="$q.platform.is.mobile"
      class="bg-white shadow-5 q-pa-sm q-py-md row justify-around"
    >
      <q-btn
        :label="stash.selected.ticket ? $t('continue') : $t('select_an_option_to_continue')"
        color="primary"
        size="lg"
        class="full-width"
        :disable="!stash.selected.ticket"
        @click="$router.push({name: 'ondemand-train-options'})"
      />
    </q-footer>
  </q-page>
</template>

<script type="text/javascript">
import { handleErrors } from 'utils/utils'
import store from 'store'
import loading from 'utils/loading'
import { tickets } from 'api/train'
import { mapGetters } from 'vuex'
import card from './card'
import i18n from 'i18n'
import { MEmptyState } from 'components/'

export default {
  name: 'OndemandTrainTickets',
  components: {
    card,
    MEmptyState
  },
  data () {
    return {
      first: null,
      standard: null,
      currentTab: 'standard'
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    })
  },
  methods: {
    setSelected (ticket) {
      const selected = this.stash.selected
      selected.ticket = ticket
      store.dispatch('ondemand/stash', { selected })
    }
  },
  async beforeRouteEnter (to, from, next) {
    const { searchToken, selected } = store.getters['ondemand/stash']
    const partner = store.getters['partner']
    loading.start({
      message: i18n.t('loading.searching.train_tickets'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const { data } = await tickets(searchToken, selected.depart.journey_id, selected.return ? selected.return.journey_id : null)
      next(vm => {
        vm.first = data.first
        vm.standard = data.standard
        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  @media (max-width: 1199px)
    padding 1em
    padding-top 2em
.layout-padding
  max-width 768px

h6
  margin 16px 0
</style>
